import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {Members} from "../../../../shared/interfaces";
import {map, startWith} from "rxjs/operators";
import {ApiService} from "../../../../services/api.service";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-customer-contact-dialog',
  templateUrl: './customer-contact-dialog.component.html',
  styleUrls: ['./customer-contact-dialog.component.css']
})
export class CustomerContactDialogComponent implements OnInit {
  @ViewChild('SPOCAutoCompleter', { read: MatAutocompleteTrigger })
  autoCompleteTrigger: MatAutocompleteTrigger;
  customerContactForm: FormGroup;
  filteredSpoc: Observable<Members[]>;
  customerControl = new FormControl('');
  customerOptions: any[] = [];
  filteredCustomer!: Observable<any[]>;
  spocControl = new FormControl('');
  SPOC_Options: Members[] = [];
  selectedCustomers: any[] = [];
  disabledSkills: any[] = [];
  customers: any[] = [];
  selectedSpcId: string;
  newlyCreatedCustomerId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public customerData: any,
    private dialogRef: MatDialogRef<CustomerContactDialogComponent>,
    private service: ApiService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.customerContactForm = this.fb.group({
      verificationStatus: ["", Validators.required],
      spocId: ["", Validators.required],
      customerId: ["", Validators.required],
    });
    if (this.customerData.SPOC) {
      this.spocControl.setValue(this.customerData.SPOC);
      this.selectedSpcId = this.customerData.SPOC.id
    }
    this.getSPOC();
    this.getCustomers();
  }

  getSPOC() {
    this.service.getSpocList().subscribe((res: any) => {
      this.SPOC_Options = res.filter((active: any) => active.active);
      this.filteredSpoc = this.spocControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSPOC(value))
      );
    });
  }

  private _filterSPOC(value: string): Members[] {
    if (typeof value !== 'string') {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.SPOC_Options.filter(option =>
      (option?.firstName && option.firstName.toLowerCase().includes(filterValue)) ||
      (option?.lastName && option.lastName.toLowerCase().includes(filterValue)) ||
      (option?.role && option.role.toLowerCase().includes(filterValue))
    );
  }

  getCustomers() {
    this.service.getActiveCustomerClients().subscribe((res: any) => {
      this.customerOptions = res.filter((customer: any) => customer?.active && customer?.companyName);
      this.filteredCustomer = this.customerControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterCustomers(value))
      );
    });
  }

  private _filterCustomers(value: any): any[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value?.companyName?.toLowerCase() || '';
    return this.customerOptions.filter(option =>
      option.companyName.toLowerCase().includes(filterValue)
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  formatSPOC(option: Members): string {
    if (!option) {
      return '';
    }
    return option.role + ' | ' + option.firstName + ' ' + option.lastName;
  }

  openAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.openPanel();
    }
  }

  addCustomer() {
    const payload = {
      companyName: this.customerControl.value
    };
    this.service.createCustomer(payload).subscribe((res) => {
      this.newlyCreatedCustomerId = res.id;
      this.toast.success('Customer Created Successfully', 'Success');
      this.customerOptions.push(res);
      this.customerControl.setValue(res.companyName);
      this.filteredCustomer = this.customerControl.valueChanges.pipe(
        startWith(res.companyName),
        map(value => this._filterCustomers(value))
      );
    }, error => {
      this.toast.error('Failed to create customer', error?.error?.message);
    });
  }

  selectedCustomer(event: any) {
    const customer = event?.option?.value;
    if (customer) {
      this.selectedCustomers.push(customer);
      this.customerControl.setValue(customer.companyName);
      this.newlyCreatedCustomerId = null
    }
  }

  chooseSpoc(event: any) {
    const selectedSpocId = event?.option?.value?.userId;
    this.selectedSpcId = selectedSpocId;
  }

  assignCustomer() {
    const customerId = this.newlyCreatedCustomerId ?? this.selectedCustomers.map(c => c.id)[0];
    const spocId = Number(this.selectedSpcId);
    const verificationStatus = this.customerContactForm.get('verificationStatus')?.value;
    const payload = {
      spocId,
      customerId: Number(customerId),
      verificationStatus
    };
    this.service.assignCustomerToWebClient(this.customerData?.id, payload).subscribe((res: any) => {
      this.toast.success('Customer assigned successfully!', 'Success');
      this.closeDialog();
      this.router.navigate(['/dashboard/web_data'], { queryParams: { tab: 1 } });
    }, error => {
      this.toast.error(error.error?.detail, 'Error');
    });
  }

}
