<div class="content-wrapper-main-inner-content ">
  <div class="content-wrapper">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center ">
        <div class="circle me-3">
          <img class="image"
               [src]=" solutionData?.logoUrl ? solutionData?.logoUrl :'https://www.shutterstock.com/shutterstock/photos/2179364083/display_1500/stock-photo-no-picture-available-placeholder-thumbnail-icon-illustration-design-2179364083.jpg'"
               alt="..."/>
        </div>
        <div class=" ">
          <div >
            <h6 style=" font-size: 20px;" > {{this.solutionData?.name ? this.solutionData?.name : '--'}}</h6>
          </div>
          <div class="d-flex align-items-center me-3">
            <span style="color: #464646; font-size: 16px;">
                Solution ID:
            </span>
            <h6 class="mb-0 sub-label" style=" font-size: 16px;" > &nbsp;{{ this.solutionData?.solutionId ? this.solutionData?.solutionId : '--'}}</h6>
          </div>
        </div>
      </div>

      <div>
          <div class="d-flex">
            <div  *ngIf="solutionAttachments?.length !== 0">
              <span class="" style="color: #464646; font-size: 16px;"> Attachments : </span>
              <div *ngFor="let attachment of solutionAttachments" class="ms-1 mt-2" style="cursor: pointer; color: #d30000; font-size: 16px;">
                <span (click)="goToAttachment(attachment.url)">
                    {{ attachment.fileName ? attachment.fileName : 'No Name'}} <i class="fas fa-download"></i>
                </span>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="d-flex mt-5">
      <div class="col-4">
        <div class="d-flex">
          <div class="">
            <span class="" style="color: #8f8888; font-size: 16px; font-weight: 600 ; "> Industry Vertical : </span>
            <p class=" ms-1" style=" font-size: 16px;font-weight: 420 ; text-align: justify">
              {{this.solutionData?.solutionInfo ? formatDataIndustryVertical(this.solutionData?.solutionInfo) : '--'}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="d-flex">
          <div class="">
            <span class="" style="color: #8f8888; font-size: 16px; font-weight: 600"> Tech Stack Used : </span>
            <p class=" ms-1" style=" font-size: 16px;font-weight: 420 ; text-align: justify" >
              {{this.solutionData?.solutionTechnology?.techStackUsed ? formatTeckStack(this.solutionData?.solutionTechnology?.techStackUsed) : '--'}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-5">
      <span style="color: #8f8888; font-size: 16px; font-weight: 600">Solution Description:</span>
      <div [innerHTML]="sanitizedDescription" style="text-align: justify"></div>
    </div>

    <div class="col-12 mt-5">
      <span style="color: #8f8888; font-size: 16px; font-weight: 600">Compliance:</span>
      <div [innerHTML]="sanitizedCompliance" style="text-align: justify"></div>
    </div>

    <div class="mt-4 ms-2 col-6 ">

      <div class="row">
        <div class="resumeAndPrice col mt-2">
          <button (click)="onNeedMoreDetail(isNeedMoreDetail)" class="btn text-white theme-btn-color">Need More Detail ?</button>
        </div>
      </div>
      <form [formGroup]="needMoreDetailForm" class="member-form"  ngNativeValidate #detailFormElement>
        <div *ngIf="isNeedMoreFormDisplay" class="form-row mt-4">
          <div class="form-group col-md-12">
            <mat-label>Tentative start time</mat-label>
            <mat-form-field appearance="outline" class="w-100 border-red">
              <mat-label>Tentative start time</mat-label>
              <mat-select formControlName="tentativeStartTime">
                <mat-option *ngFor="let duration of durations" [value]="duration.value" class="dropItems"
                            (click)="$event.stopImmediatePropagation()">
                  <a class="dropdown-item">
                    {{ duration.label }}
                  </a>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <span *ngIf="isNeedMoreFormDisplay ">

      <div class="form-row">
         <div class="form-group col-md-12">
           <mat-label>Notes</mat-label>
           <mat-form-field appearance="outline" class="w-100 border-red">
             <mat-label>Notes</mat-label>
             <textarea formControlName="notes" matInput class="form-control bg-transparent"
                       placeholder=" Add Note..."
                       style="height: 80px;"></textarea>
           </mat-form-field>
         </div>
      </div>
      </span>
      </form>
      <div *ngIf="isNeedMoreFormDisplay" class="d-flex justify-content-end ">
        <button mat-button (click)="onSubmit()"
                class="btn theme-bg-color text-white sendButton ">{{ 'Send Request' }}
        </button>
      </div>
    </div>
  </div>

</div>

