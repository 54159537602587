import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {CustomerContact, myTaskDTO} from "../../../shared/interfaces";
import {debounce} from "lodash";
import {MatPaginator} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {PopUpComponent} from "../../../util/pop-up/pop-up.component";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {ToastrService} from "ngx-toastr";
import {ResetFiltersService} from "../../../services/resetFilters.service";
import {
  DialogboxChangeAssigneeComponent
} from "../my-tasks/dialogbox-change-assignee/dialogbox-change-assignee.component";
import {DataService} from "../../../services/data.service";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {ReassignCustomerComponent} from "../reassign-customer/reassign-customer.component";

export interface FiltersDto {
  query: string;
  contactName: string;
  customerName: string;
  email: string;
  spocIds: number[];
  emailSentStatus: string;
  includeArchive: boolean;
  dateSearchCriteria: {
    filterType: string,
    from: string,
    to: string,
  }
}

@Component({
  selector: 'app-customers-contacts',
  templateUrl: './customers-contacts.component.html',
  styleUrls: ['./customers-contacts.component.css']
})
export class CustomersContactsComponent implements OnInit, AfterViewInit {
  customerContacts: CustomerContact[] = [];
  currentPage: number = 0;
  pageSize = 12;
  totalContacts = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  totalPages: number = 0
  query: string = ''
  showArchived: boolean = false;
  showLoader: boolean = true;
  searchFieldName = ['', 'contactName', 'email', 'customerName', 'spocIds', 'emailSentStatus', 'dateSearchCriteria', ''];
  filters: FiltersDto = {
    query: '',
    contactName: "",
    customerName: "",
    email: '',
    spocIds: [],
    emailSentStatus: '',
    includeArchive: false,
    dateSearchCriteria: {
      filterType: "CREATED",
      from: "",
      to: "",
    },
  };
  search: string;
  isDropdownOpen = false;
  selectedTasks: Set<number> = new Set();
  selectAll: boolean = false;

  constructor(
    private apiService: ApiService,
    public dialog: NgDialogAnimationService,
    private toastr: ToastrService,
    private resetFilters: ResetFiltersService,
    private dataService: DataService,
  ) {
  }

  ngOnInit(): void {
    this.fetchCustomerContacts(this.currentPage, this.pageSize, this.filters);
    this.fetchCustomerContacts = debounce<any>(this.fetchCustomerContacts, 600);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.fetchCustomerContacts(this.paginator.pageIndex, this.paginator.pageSize, this.filters);
      })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.fetchCustomerContacts(this.paginator.pageIndex, this.paginator.pageSize, this.filters)),
        )
        .subscribe();
    }
  }

  fetchCustomerContacts(page: number, size: number, payload: FiltersDto): void {
    this.showLoader = true;
    this.apiService.getAllCustomerContacts(page, size, payload).subscribe(res => {
        this.customerContacts = res.content;
        this.currentPage = res.page.number;
        this.pageSize = res.page.size;
        this.totalPages = res.page.totalPages;
        this.showLoader = false;
        this.paginator.pageIndex = res.page.number;
        this.paginator.pageSize = res.page.size;
        this.totalContacts = res.page.totalElements;
        this.updateSelectAllState();
      },
      (error) => {
        console.error(error);
        this.showLoader = false;
      }
    );
  }

  toggleActiveCustomers(event: any): void {
    this.showArchived = event.checked;
    this.filters.includeArchive = this.showArchived;
    this.paginator.pageIndex = 0;
    this.fetchCustomerContacts(this.paginator.pageIndex, this.pageSize, this.filters);
  }

  onFiltersChanged(updatedFilters: any) {
    this.filters = {
      ...updatedFilters,
      query: this.search
    };
    this.paginator.pageIndex = 0;
    this.filters.query = this.search;
    this.fetchCustomerContacts(this.paginator.pageIndex, this.pageSize, this.filters);
  }

  updateSearch() {
    this.filters.query = this.search;
    this.paginator.pageIndex = 0;
    this.fetchCustomerContacts(this.paginator.pageIndex, this.pageSize, this.filters);
  }

  clearFilter() {
    this.filters.contactName = "";
    this.filters.customerName = "";
    this.filters.email = '';
    this.filters.spocIds = [];
    this.filters.emailSentStatus = '';
    this.resetFilters.emitChange(true);
    this.fetchCustomerContacts(0, this.pageSize, this.filters);
  }

  confirmationSendEmail(contactId: number) {
    const dialogRef2 = this.dialog.open(PopUpComponent, {
      data: {
        title: 'Alert',
        message: 'Are you sure you want to Send Email?',
        type: 'confirmation'
      }
    });

    dialogRef2.afterClosed().subscribe((res) => {
      if (res) {
        this.apiService.sendEmail(contactId).subscribe({
          next: () => {
            this.fetchCustomerContacts(this.paginator.pageIndex, this.pageSize, this.filters);
            this.toastr.success('Email sent successfully', 'Success');
          },
          error: (err) => {
            console.error('Error sending email', err);
            this.toastr.error('Error while sending email', 'Error');
          }
        });
      }
    });
  }

  openDialogBox() {
    this.isDropdownOpen = false;
    if (this.selectedTasks.size > 0) {
      const dialogRef = this.dialog.open(DialogboxChangeAssigneeComponent, this.dataService.AssigneeConfig({
        customerSelectedTasks: this.selectedTasks,
        showOnlyActiveMembers: true
      }));
      dialogRef.afterClosed().subscribe(() => {
        this.selectedTasks.clear();
        this.fetchCustomerContacts(this.currentPage, this.pageSize, this.filters);
        this.selectAll = false;
      });
    } else {
      this.toastr.info('Please select the task by clicking the checkbox provided in the list', 'Alert', {
        positionClass: "toast-bottom-right"
      });
    }
  }

  dialogBoxReassignCustomer(data: CustomerContact) {
    const dialogRef = this.dialog.open(ReassignCustomerComponent, this.dataService.AssigneeConfig({
      data: data,
    }))
    dialogRef.afterClosed().subscribe(() => {
      this.fetchCustomerContacts(this.currentPage, this.pageSize, this.filters);
    })
  }

  toggleSelectAll() {
    if (this.selectAll) {
      this.customerContacts.forEach((contact) => {
        this.selectedTasks.add(contact.id);
      });
    } else {
      this.customerContacts.forEach((contact) => {
        this.selectedTasks.delete(contact.id);
      });
    }
    this.updateSelectAllState();
  }

  selectTasks(event: MatCheckboxChange, contact: CustomerContact) {
    if (event.checked) {
      this.selectedTasks.add(contact.id);
    } else {
      this.selectedTasks.delete(contact.id);
    }
    this.updateSelectAllState();
  }

  updateSelectAllState() {
    this.selectAll = this.customerContacts.length > 0 && this.customerContacts.every((contact) => this.selectedTasks.has(contact.id));
  }

  dateTypeChanged($event: boolean) {
    if ($event === true) {
      this.filters.dateSearchCriteria.filterType = "UPDATED";
    } else if ($event === false) {
      this.filters.dateSearchCriteria.filterType = "CREATED";
    }
    this.fetchCustomerContacts(0, this.pageSize, this.filters);
  }

}
