<div class="modal-popUp-content content-wrapper-main-inner-content job_wrapper overflow-hidden">
  <div class="member_wrapper" mat-dialog-title>
    <div class="modal-inner-content">
      <div class="d-flex align-items-center justify-content-between dialogBackgroundColor p-2">
        <div class="ms-2">
          <h5 class="content-header">
            Reassign Customer
          </h5>
        </div>
        <div type="button" (click)="closeDialog()" class="cross_icon">
          <a>
            <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
              <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div mat-dialog-content>
      <p class="ms-3">Select the Assignee from below</p>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Select Assignee</mat-label>
          <mat-select (selectionChange)="onChangeAssignee($event)">
            <mat-option *ngFor="let assignee of assigneeList" [value]="assignee">
              {{ assignee.firstName + ' ' + assignee.lastName + ' | ' + assignee.role }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <div class="me-3">
        <app-button [width]="'100px'" [height]="'35px'" type="text" (buttonClick)="closeDialog()">
          Cancel
        </app-button>
      </div>
      <div class="me-3">
        <app-button [width]="'100px'" [height]="'35px'" type="primary" (buttonClick)="submitAssignee()">
          Assign
        </app-button>
      </div>
    </div>
  </div>
</div>

