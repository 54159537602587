import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordEmailService {
  private inputData: string = '';

  constructor() { }
  setInputData(data: string): void {
    this.inputData = data;
  }
  getInputData(): string {
    return this.inputData;
  }
}
