import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from "../../../../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {Candidate, PlaceDto} from "../../../../shared/interfaces";
import {ToastrService} from "ngx-toastr";
import {Subject, throwError} from "rxjs";
import {catchError, debounceTime, delay, distinctUntilChanged, filter, map, takeUntil, tap} from "rxjs/operators";
import {Observable} from "rxjs/internal/Observable";

interface FormField {
  label: string;
  type: string;
  required: boolean;
  formControlName: string;
}

@Component({
  selector: 'app-web-candidate-information',
  templateUrl: './web-candidate-information.component.html',
  styleUrls: ['./web-candidate-information.component.css']
})
export class WebCandidateInformationComponent implements OnInit, OnChanges {
  @Input() customerInfoData: Candidate;
  @Input() formFields!: FormField[];
  candidateForm: FormGroup;
  experienceYears: number[] = [];
  experienceMonths: number[] = [];
  candidateId: number;
  countryControl = new FormControl();
  stateControl = new FormControl('');
  cityControl = new FormControl('');
  countryServerCtrl = new FormControl();
  stateServerCtrl = new FormControl();
  cityServerCtrl = new FormControl();
  selectedCountry: any | undefined;
  selectedState: any | undefined;
  selectedCity: any | undefined;
  searchCountry: boolean = false;
  searchState: boolean = false;
  searchCity: boolean = false;
  countries: PlaceDto[] = [];
  states: PlaceDto[] = [];
  cities: PlaceDto[] = [];
  protected _onDestroy = new Subject<void>();

  loading: boolean = false;
  @Input() handleCandidateFormSubmit!: (webCandidateId: number, payload: any) => Observable<any>;
  linkedInURL:string = 'https://www.linkedin.com/';
  updateURL:boolean = false;
  currentRoute: string = "";
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    console.log(this.route?.snapshot?.url[1]?.path)
    if(this.route?.snapshot?.url[1]?.path === 'candidate') {
      this.currentRoute = 'candidate'
    } else if(this.route?.snapshot?.url[1]?.path === 'customer') {
      this.currentRoute = 'customer'
    }
    this.candidateId = this.route.snapshot.params['id'];
    this.experienceYears = Array.from({length: 32}, (_, i) => i);
    this.experienceMonths = Array.from({length: 13}, (_, i) => i);
    this.initializeForm();
    this.onSearchCountry();
    this.onSearchState();
    this.onSearchCities();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerInfoData'] && changes['customerInfoData'].currentValue) {
      this.patchFormData();
    }
  }

  initializeForm(): void {
    const formGroupConfig: { [key: string]: any } = {};
    this.formFields.forEach((field: FormField) => {
      if (field.label === 'LinkedIn URL') {
        formGroupConfig[field.formControlName] = ['https://www.linkedin.com/'];
      } else if (field.label === 'Email') {
        formGroupConfig[field.formControlName] = ['',[Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}')]];
      } else {
        formGroupConfig[field.formControlName] = ['', field.required ? [Validators.required] : [],];
      }
    });
    formGroupConfig['notes'] = [''];
    formGroupConfig['currentLocation'] = this.fb.group({
      streetAddress: [''],
      postalCode: ['', [Validators.pattern(/^\d{6}$/)]],
      country: this.fb.group({
        id: [null],
        name: [null],
        formattedAddress: [null],
      }),
      state: this.fb.group({
        id: [null],
        name: [null],
        formattedAddress: [null]
      }),
      city: this.fb.group({
        id: [null],
        name: [null],
        formattedAddress: [null]
      }),
    });
    this.candidateForm = this.fb.group(formGroupConfig);
  }

  patchFormData(): void {
    if (this.customerInfoData && this.candidateForm) {
      this.linkedInURL = (this.customerInfoData.linkedInURL || this.customerInfoData.linkedIn) ?? '';
      if (this.linkedInURL) {
        this.updateURL = false;
      }
      this.candidateForm.patchValue({
        firstName: this.customerInfoData.firstName,
        lastName: this.customerInfoData.lastName,
        title: this.customerInfoData.title,
        experienceYear: this.customerInfoData.experienceYear,
        experienceMonth: this.customerInfoData.experienceMonth,
        mobile: this.customerInfoData.mobile,
        email: this.customerInfoData.email,
        linkedInURL: this.linkedInURL,
        currentCtc: this.customerInfoData.currentCtc,
        expectedCtc: this.customerInfoData.expectedCtc,
        notes: this.customerInfoData.notes,
        company: this.customerInfoData.company,
      });

      if (this.currentRoute === 'candidate' && this.customerInfoData.currentLocation) {
        const { country, state, city, streetAddress, postalCode } = this.customerInfoData.currentLocation;
        this.selectedCountry = country;
        this.selectedState = state;
        this.selectedCity = city;
        this.countries = country ? [country] : [];
        this.states = state ? [state] : [];
        this.cities = city ? [city] : [];
        this.candidateForm.get('currentLocation.country')?.patchValue(country);
        this.countryControl.setValue(country);
        this.candidateForm.get('currentLocation.state')?.patchValue(state);
        this.stateControl.setValue(state);
        this.candidateForm.get('currentLocation.city')?.patchValue(city);
        this.cityControl.setValue(city);
        if (streetAddress) {
          this.candidateForm.get('currentLocation.streetAddress')?.patchValue(streetAddress);
        }
        if (postalCode) {
          this.candidateForm.get('currentLocation.postalCode')?.patchValue(postalCode);
        }
      } else if (this.currentRoute === 'customer' && this.customerInfoData.addressInformation) {
        const { country, state, city, address, pinCode } = this.customerInfoData.addressInformation;
        this.selectedCountry = country;
        this.selectedState = state;
        this.selectedCity = city;
        this.countries = country ? [country] : [];
        this.states = state ? [state] : [];
        this.cities = city ? [city] : [];
        this.candidateForm.get('currentLocation.country')?.patchValue(country);
        this.countryControl.setValue(country);
        this.candidateForm.get('currentLocation.state')?.patchValue(state);
        this.stateControl.setValue(state);
        this.candidateForm.get('currentLocation.city')?.patchValue(city);
        this.cityControl.setValue(city);
        if (address) {
          this.candidateForm.get('currentLocation.streetAddress')?.patchValue(address);
        }
        if (pinCode) {
          this.candidateForm.get('currentLocation.postalCode')?.patchValue(pinCode);
        }
      }
    }
  }

  onSearchCountry() {
    this.countryServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchCountry = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getCountries(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchCountry = false;
      }, error: err => {
        this.searchCountry = false;
      }
    });
  }

  onSearchCities() {
    this.cityServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchCity = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getCities(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchCity = false;
      }, error: err => {
        this.searchCity = false;
      }
    });
  }

  onSearchState() {
    this.stateServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchState = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getStates(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchState = false;
      }, error: err => {
        this.searchState = false;
      }
    });
  }

  getCities(query: string): void {
    this.apiService.getCities(this.candidateForm?.get("currentLocation")?.get("state")?.get('id')?.value, query).then(res => {
      this.cities = this.selectedCity ? [this.selectedCity, ...res] : res;
    });
  }

  getCountries(query: string): void {
    this.apiService.getCountries(query).then((res: any) => {
      this.countries = this.selectedCountry ? [this.selectedCountry, ...res] : res;
      return (res);
    });
  }

  getStates(query: string): void {
    this.apiService.getStates(this.candidateForm?.get("currentLocation")?.get("country")?.get('id')?.value, query).then(res => {
      this.states = this.selectedState ? [this.selectedState, ...res] : res;
    });
  }


  selectCountry(country: PlaceDto): void {
    this.candidateForm?.get("currentLocation")?.get("country")?.setValue(country);
    this.selectedState = undefined;
    this.selectedCity = undefined;
    this.selectedCountry = country;
    this.candidateForm?.get("currentLocation")?.get("state")?.reset();
    this.candidateForm?.get("currentLocation")?.get("city")?.reset();
  }

  selectState(state: PlaceDto): void {
    this.candidateForm?.get("currentLocation")?.get("state")?.get('id')?.setValue(state.id);
    this.candidateForm?.get("currentLocation")?.get("state")?.get('name')?.setValue(state.name);
    this.candidateForm?.get("currentLocation")?.get("state")?.get('formattedAddress')?.setValue(state.formattedAddress);
    this.selectedState = state;
    this.selectedCity = undefined;
    this.candidateForm?.get("currentLocation")?.get("city")?.reset();
  }

  selectCity(city: PlaceDto): void {
    this.candidateForm?.get("currentLocation")?.get("city")?.setValue(city);
  }

  compareFunction(optionValue: PlaceDto, selectedValue: PlaceDto): boolean {
    return optionValue && selectedValue && optionValue === selectedValue;
  }

  onSubmit() {
    this.loading = true;
    if (this.candidateForm.invalid) {
      this.markAllFieldsAsTouched(this.candidateForm);
      if (this.candidateForm.get('email')?.invalid) {
        this.toastr.error('Invalid email format. Please enter a valid email address.');
      } else {
        this.toastr.error('Please fix the errors in the form before submitting.');
      }
      this.loading = false;
      return;
    }

    this.updateURL = false;

    let formData;
    if (this.currentRoute === 'candidate') {
      formData = {
        ...this.candidateForm.value,
        currentLocation: {
          postalCode: this.candidateForm.get('currentLocation')?.get('postalCode')?.value,
          streetAddress: this.candidateForm.get('currentLocation')?.get('streetAddress')?.value,
          cityId: this.candidateForm.get('currentLocation')?.get('city')?.get('id')?.value,
          stateId: this.candidateForm.get('currentLocation')?.get('state')?.get('id')?.value,
          countryId: this.candidateForm.get('currentLocation')?.get('country')?.get('id')?.value,
        },
      };
    } else {
      formData = {
        firstName: this.candidateForm.get('firstName')?.value || '',
        lastName: this.candidateForm.get('lastName')?.value || '',
        company: this.candidateForm.get('company')?.value || '',
        email: this.candidateForm.get('email')?.value || '',
        mobile: this.candidateForm.get('mobile')?.value || '',
        linkedURL: this.candidateForm.get('linkedInURL')?.value || '',
        address: this.candidateForm.get('currentLocation')?.get('streetAddress')?.value || '',
        pinCode: this.candidateForm.get('currentLocation')?.get('postalCode')?.value || '',
        city: this.candidateForm.get('currentLocation')?.get('city')?.get('id')?.value || 0,
        state: this.candidateForm.get('currentLocation')?.get('state')?.get('id')?.value || 0,
        country: this.candidateForm.get('currentLocation')?.get('country')?.get('id')?.value || 0
      };
    }

    this.handleCandidateFormSubmit(this.route.snapshot.params['id'], formData).pipe(
      tap(response => {
        this.linkedInURL = response.linkedInURL;
      }),
      catchError(error => {
        this.toastr.error('Error submitting form: ' + error.message);
        return throwError(() => error);
      })
    ).subscribe({
      next: () => {},
      error: () => {this.loading = false;},
      complete: () => {
        this.loading = false;
      }
    });
  }

  markAllFieldsAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.markAllFieldsAsTouched(control);
      }
    });
  }

  updateUrl() {
    this.updateURL = !this.updateURL;
  }

  goToLink(url: string) {
    window.open(url, " ");
  }

}
