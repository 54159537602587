<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Manage Jobs</h5>
        <div class="page-title-right me-3">
          <a routerlink="add-vendor" (click)="openDialogs('new',null)" class="btn btn-create theme-btn-color"> <i
            class="fas fa-plus fa-xs me-2"></i>
            Create New Job
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <div class="row mb-2">
        <div class="col-md-4">
          <div id="data-table_filter" class="d-flex justify-content-end dataTables_filter">
            <label class="w-100 search-input">
              <input [(ngModel)]="search" class="form-control" placeholder="Search for job"
                     (keyup)="getAllJobs(this.currentPage,this.pageSize,showArchived)" (search)="getAllJobs(this.currentPage,this.pageSize,showArchived)" type="search"
                     aria-controls="data-table">
              <button class="search-button" (click)="getAllJobs(this.currentPage,this.pageSize,showArchived)" type='button'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                    fill="#808080"/>
                </svg>
              </button>
            </label>
          </div>
        </div>
        <div class="col-md-8 entry-label d-flex justify-content-end">
          <mat-slide-toggle
            class="example-margin"
            color="primary"
            [checked]="showArchived"
            (change)="changeArchived($event)">
            <div class="ActiveButton">Show In-active</div>
          </mat-slide-toggle>
          <div class="ms-3 p-0">
            <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
              <i class="fas fa-redo me-2"></i>
              Reset Filters
            </button>
          </div>
        </div>
      </div>
      <div>
        <table class="table table-striped">
          <thead class="tableview theme-bg-color">
          <tr>
            <th>Job ID</th>
            <th>Job Title</th>
            <th>Job Posting Date</th>
            <th>Customer Name</th>
            <th>Experience Range</th>
            <th>Created By </th>
            <th style="text-align: center">Job Link</th>
            <th style="text-align: center">Job Status</th>
            <th style="text-align: center">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr search-filter [searchFields]="searchFields" [filters]="filters" (filtersChanged)="onFiltersChanged($event)"  (resetFilters)="clearFilter()"></tr>
          <ng-container *ngFor="let job of jobs">
            <tr>
              <td style="width: 13%"><span style="cursor: pointer" class="theme-text-color fw-bold" title="Edit "
                                           (click)="openDialogs('edit',job)">{{ job.jobId }}
              </span>
              </td>
              <td style="width: 13%">{{ (job.title != null && job.title != '') ? job.title : '--' }}</td>
              <td style="width: 13%">
                <span class="p-0 m-0">{{ job.createdDate | date: 'd MMM y hh:mm a' }}</span>
              </td>
              <td
                style="width: 13%">{{ job.customerContact != null ? job.customerContact.customer.companyName : '--' }}
              </td>
              <td
                style="width: 13%">{{ job.minExperience }} {{ job.minExperience == null && job.maxExperience == null ? '--' : '-' }} {{ job.maxExperience }} {{ job.minExperience == null && job.maxExperience == null ? '' : 'yrs' }}
              </td>
              <td
                      style="width: 13%">{{ job.createdBy != null ? job.createdBy?.firstName  + (job.createdBy.lastName ? ' ' + job.createdBy.lastName : '') : '--' }}
              </td>
              <td style="cursor: pointer; text-align: center; width: 13%" (click)="getJobDataForCopy(job.id)"><img
                src="assets/Copy%20Clipboard_blue.svg"></td>
              <td style="width: 13%;    text-align: center;">
                <label class="switch" style="color: #14804A; font-weight: 500;background-color: #E1FCEF"
                       *ngIf="job?.active">Active</label>
                <label class="switch" style="color: #848484; font-weight: 500;background-color: #E7E7E7"
                       *ngIf="!job?.active">Inactive</label>
              </td>
              <td>
                <button id="more-btn dropdownMenuButton1" class="more-btn" data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        type="button">
                  <span class="more-dot"></span>
                  <span class="more-dot"></span>
                  <span class="more-dot"></span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item"
                       (click)="deleteConfirmation('Delete',job.id)">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                          fill="#ED0F0F"/>
                      </svg>
                      Delete Permanent
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngIf="!showLoader && jobs.length == 0" class="d-flex justify-content-center mt-8">
          <img src="assets/images.png"><br>
          <h4> No Result Found</h4>
      </div>
      <mat-paginator [length]="totalJobs"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="[10,12,25,50,100]"
                     [hidden]="totalJobs==0"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>

</div>
