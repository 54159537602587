import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ApiService} from "../services/api.service";
import {DataService} from "../services/data.service";
import {LoggedInUser, SessionMap} from "../shared/interfaces";
import {Common} from "../shared/Common";
import {ThemeLoaderService} from "../services/theme-loader.service";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {ForgotPasswordEmailService} from "../services/forgotPasswordEmail.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  loginRadio: FormGroup | any;
  visible1 = true;
  visible2 = false;
  password = '';
  email:string ='';
  loginform: FormGroup;
  showloader = false;
  errorMessage: string = '';

  @ViewChild('loginPasswordInput') loginPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconLoginPasswordInputButton') faIconLoginPasswordInputButton: ElementRef<HTMLElement>;

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private router: Router,
    private dataService: DataService,
    private themeLoaderService: ThemeLoaderService,
    private emailService: ForgotPasswordEmailService
  ) {
  }

  login(): void {
    this.showloader = true;
    this.errorMessage = '';
    let payload = this.loginform.value;
    if (localStorage.getItem('sessions')) {
      let str = localStorage.getItem('sessions');
      if (str) {
        let sessionMaps: SessionMap[] = JSON.parse(str);
        sessionMaps.forEach(map => {
          if (map.userEmail === payload.username) {
            payload.sessionId = map.sessionId;
          }
        });
      }
    }
    if (this.loginform.invalid) {
      this.showloader = false;
      if (this.loginform.get('password')?.errors) {
        if (this.loginform.get('password')?.errors?.required) {
          this.errorMessage = 'Password cannot be empty.';
        }
      }
      if (this.loginform.get('username')?.errors) {
        if (this.loginform.get('username')?.errors?.required) {
          this.errorMessage = 'Email is required.';
        } else if (this.loginform.get('username')?.errors?.pattern) {
          this.errorMessage = 'Please enter a valid email address.';
        }
      }
      if (this.loginform.get('username')?.errors || this.loginform.get('password')?.errors) {
        return;
      }
    } else {
      this.errorMessage = '';
    }
    this.service.login(payload).then(res => {
      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('orgId', res.org_id);
      localStorage.setItem('name', res.name);
      localStorage.setItem('userId', res.user_id);
      localStorage.setItem('role', res.role);
      const rootVariable = document.documentElement;
      if (res.session_id) {
        let currentSessionMap: SessionMap = {
          userEmail: res.user_email,
          sessionId: res.session_id
        };
        if (localStorage.getItem('sessions')) {
          let str = localStorage.getItem('sessions');
          if (str) {
            let sessionMaps: SessionMap[] = JSON.parse(str);
            sessionMaps.push(currentSessionMap);
            localStorage.setItem('sessions', JSON.stringify(sessionMaps));
          }
        } else {
          let sessionMaps: SessionMap[] = [];
          sessionMaps.push(currentSessionMap);
          localStorage.setItem('sessions', JSON.stringify(sessionMaps));
        }
      }
      this.service.getLoginUser().subscribe({
        next: res => {
          const primaryColor = res?.theme?.colour ?? '#76ba991a';
          const secondaryColor = Common.rgbaToHex(Common.hexToRGBA(primaryColor, 0.1));
          const ColorObj = {primaryColor, secondaryColor};
          localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
          rootVariable.style.setProperty('--primaryColor', primaryColor);
          rootVariable.style.setProperty('--secondaryColor', secondaryColor);
          this.themeLoaderService.setTheme(primaryColor, secondaryColor);
        },
        error: err => {
          console.error('Error fetching user theme:', err);
        },
        complete: () => {
          this.dataService.isLoading.next(false);
        }
      });

      this.showloader = false;
      this.router.navigate(['/dashboard']);
    }, (e) => {
      this.showloader = false;
      if (e.error && e.error.status) {
        switch (e.error.status) {
          case 401:
            this.errorMessage = e.error.message === 'Unauthorized' ? 'Unauthorized access. Please check your credentials.' : e.error.message;
            break;
          case 400:
            this.errorMessage = e.error.message;
            break;
          default:
            this.errorMessage = e.error.message || 'An unexpected error occurred. Please try again.';
            break;
        }
      } else {
        this.errorMessage = 'An unexpected error occurred. Please try again.';
      }
    });
  }

  togglePassword(): void {
    if (this.loginPasswordInput.nativeElement.type == 'text') {
      this.loginPasswordInput.nativeElement.type = 'password';
      this.faIconLoginPasswordInputButton.nativeElement.className = 'fas fa-eye';
    } else {
      this.faIconLoginPasswordInputButton.nativeElement.className = 'fas fa-eye-slash';
      this.loginPasswordInput.nativeElement.type = 'text';
    }
  }

  getForgotPassword(): string {
    return '/forget-password/';
  }

  ngOnInit(): void {
    this.loginRadio = this.fb.group({
      type: [''],
    });
    localStorage.setItem('loginType', 'Vendor');
    this.loginRadio.get('type').setValue('Vendor');
    this.loginform = this.fb.group({
      username: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}')]],
      password: ['', Validators.required]
    });
    if (localStorage.getItem('access_token') && localStorage.getItem('role') == 'ADMIN') {
      this.router.navigate(['/dashboard']);
    } else if (localStorage.getItem('access_token') && localStorage.getItem('role') == 'Sales') {
      this.router.navigate(['/client']);
    }
    this.loginform.get('username')?.valueChanges.pipe(debounceTime(100), distinctUntilChanged()).subscribe(() => {
      this.handleEmailChange();
      this.emailService.setInputData(this.email);
    });
    this.loginform.get('password')?.valueChanges.subscribe(() => {
      this.handlepasswordChange();
    });

  }

  handleEmailChange(): void {
    const usernameControl = this.loginform.get('username');
    if (usernameControl?.touched || usernameControl?.dirty) {
      if (usernameControl?.invalid) {
        this.showloader = false;
        if (usernameControl.errors) {
          if (usernameControl.errors.required) {
            this.errorMessage = 'Email is required.';
          } else if (usernameControl.errors.pattern) {
            this.errorMessage = 'Please enter a valid email address.';
          }
        }
      } else {
        this.errorMessage = '';
      }
    }
  }

  handlepasswordChange(): void {
    const passwordControl = this.loginform.get('password');
    if (passwordControl?.touched || passwordControl?.dirty) {
      if (passwordControl?.invalid) {
        this.showloader = false;
        if (passwordControl.errors) {
          if (passwordControl.errors.required) {
            this.errorMessage = 'Password cannot be empty.';
          }
        }
      } else {
        this.errorMessage = '';
      }
    }
  }
}
