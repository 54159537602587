<section class="login__wrapper">
  <div class="row">
    <div class="col-lg-6 col-md-12 form-section">
      <div class="logo-container">
        <img src="../../assets/adminLogo.png" alt="Logo" class="logo"/>
      </div>
      <div class="form-container">
        <div *ngIf="currentStep === 1" class="form-content">
          <h4 class="heading_2">Forgot Password?</h4>
          <p class="subtext">Enter your email to receive an OTP</p>
          <form [formGroup]="emailFormGroup" (submit)="sendOtpMail()">
            <div class="form-group mb-4">
              <label for="email">Email</label>
              <input formControlName="email" (change)="handleEmailChange()" type="text" id="email" placeholder="username@mailid.com" required/>
            </div>
            <div class="d-flex align-items-center justify-content-center mb-3">
              <div class="text-danger">{{ this.errorMessage }}</div>
            </div>
            <button *ngIf="!emailLoader" class="btn btn-theme w-100" type="submit">Send OTP</button>
            <div *ngIf="emailLoader" class="loader-wrapper">
              <button class="btn btn-theme w-100" disabled>
                <div class="spinner-border" role="status"></div>
              </button>
            </div>
          </form>
        </div>
        <div *ngIf="currentStep === 2" class="form-content">
          <h4 class="heading_2">Verify OTP</h4>
          <p class="subtext">OTP sent to {{ emailFormGroup.get('email')?.value }}</p>
          <form [formGroup]="otpFormGroup" (submit)="verifyOtp()">
            <div class="mb-3">
              <ng-otp-input [formCtrl]="otp" [config]="{length:8}"></ng-otp-input>
            </div>
            <div class="d-flex align-items-center justify-content-center mb-3">
              <div class="text-danger">{{ this.errorMessage }}</div>
            </div>
            <button *ngIf="!otpLoader" class="btn btn-theme w-100" type="submit">Verify OTP</button>
            <div *ngIf="otpLoader" class="loader-wrapper">
              <button class="btn btn-theme w-100" disabled>
                <div class="spinner-border" role="status"></div>
              </button>
            </div>
          </form>
        </div>
        <div *ngIf="currentStep === 3" class="form-content">
          <h4 class="heading_2">Reset Password</h4>
          <p class="subtext">Create a new password</p>
          <form [formGroup]="passwordFormGroup" (submit)="changePassword()">
            <div class="form-group mb-4">
              <label for="password">Password</label>
              <div class="password-wrapper">
                <input
                  #passwordInput
                  formControlName="password"
                  type="password"
                  id="password"
                  placeholder="********"
                  required
                />
                <i #faIconPasswordInputButton (click)="togglePassword('password')" class="fas fa-eye"></i>
              </div>
            </div>
            <div class="form-group mb-4">
              <label for="confirmPassword">Confirm Password</label>
              <div class="password-wrapper">
                <input
                  #confirmPasswordInput
                  formControlName="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  placeholder="********"
                  required
                />
                <i #faIconConfirmPasswordInputButton (click)="togglePassword('confirmPassword')" class="fas fa-eye"></i>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center mb-3">
              <div class="text-danger">{{ this.errorMessage }}</div>
            </div>
            <button *ngIf="!passwordLoader" class="btn btn-theme w-100" type="submit">Reset Password</button>
            <div *ngIf="passwordLoader" class="loader-wrapper">
              <button class="btn btn-theme w-100" disabled>
                <div class="spinner-border" role="status"></div>
              </button>
            </div>
          </form>
        </div>
        <div *ngIf="currentStep === 4" class="form-content">
          <div class="form-group mb-4">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <svg fill="#26C263" width="150px" height="150x" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                   stroke="#26C263">
                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M745.472 352.256c-8.192-4.096-20.48-4.096-28.672 0L462.848 614.4 344.064 491.52c-8.192-8.192-20.48-8.192-28.672 0s-8.192 20.48-4.096 28.672L446.464 655.36h4.096s4.096 0 4.096 4.096h16.384s4.096 0 4.096-4.096h4.096l270.335-274.432c0-8.192 0-20.48-4.096-28.672z"/>
                  <path
                    d="M512 1024C229.376 1024 0 794.624 0 512S229.376 0 512 0s512 229.376 512 512-229.376 512-512 512zm0-983.04C253.952 40.96 40.96 253.952 40.96 512S253.952 983.04 512 983.04 983.04 770.048 983.04 512 770.048 40.96 512 40.96z"/>
                </g>
              </svg>
              <h4 class="mb-3">Password Changed</h4>
              <p class="mb-3">Your password has been changed successfully.</p>
              <button type="button" class="btn btn-primary" [routerLink]="['/admin/login']">
                Back to Login
              </button>
            </div>
          </div>
        </div>
        <div class="mt-3" style="font-size: 14px">
          <p *ngIf="currentStep !== 4">I remember my Password.
            <span class="text-primary cursor-p" [routerLink]="['/admin/login']">Back to login</span>
          </p>
        </div>
      </div>
      <div class="footer-text">
        © 2024 Copyright, <b style="color:#1E293B">Linkage IT</b><sup>®</sup>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 bg-img">
      <div class="informeson">
        <img src="../../assets/loginBackgroundImage.png" alt="Background Image"/>
      </div>
    </div>
  </div>
</section>
