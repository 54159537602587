<div class="content-wrapper">
  <h5 class="page-title">Web Data </h5>
  <mat-tab-group mat-align-tabs="start" [selectedIndex]="selected"  (selectedIndexChange)="tabOnChange($event)">
    <mat-tab label="Web Candidate">
      <div class="col-md-12 mt-4">
        <div class="content-wrapper-main-inner-content">
          <div class="row mb-2">
            <div class="col-md-4">
              <div id="data-table_filter" class="d-flex justify-content-end dataTables_filter">
                <label class="w-100 search-input">
                  <input [(ngModel)]="search" (ngModelChange)="updateSearch()" class="form-control"
                         placeholder="Search Candidate" type="search" aria-controls="data-table">
                  <button class="search-button"
                          (click)="this.getAllWebCandidates(this.currentPage,this.pageSize, [],this.search)"
                          type='button'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                        fill="#808080"/>
                    </svg>
                  </button>
                </label>
              </div>
            </div>

            <div class="col-md-8 entry-label d-flex justify-content-end align-items-center">
              <div class="ms-3 p-0">
                <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
                  <i class="fas fa-redo me-2"></i>
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
          <div>
            <table class="table table-striped ">
              <thead class="tableview theme-bg-color">
              <tr>
                <th>Candidate Name</th>
                <th>Job Title</th>
                <th>Experience</th>
                <th>Primary Skills</th>
                <th>Location Preference</th>
                <th>Notice(Days)</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
              </thead>
              <tbody>
              <tr search-filter [searchFields]="searchFieldName" [filters]="filters"
                  (filtersChanged)="onFiltersChanged($event)" (resetFilters)="clearFilter()"></tr>

              <ng-container *ngFor="let candidate of candidates">
                <tr>
                  <td style="width: 13%" class="theme-text-color fw-bold cursor-p"
                      [routerLink]="['/dashboard/web_data/candidate',candidate.id]">
                    {{ candidate.firstName }} {{ candidate.middleName ? candidate.middleName : '' }} {{ candidate.lastName ? candidate.lastName : '' }}
                  </td>
                  <td style="width: 13%">
                    {{ candidate.title ? candidate.title : '--' }}
                  </td>
                  <td style="width: 13%">
                    {{
                      (candidate.experienceYear || candidate.experienceMonth) ?
                        (candidate.experienceYear ? candidate.experienceYear + ' yrs ' : '') +
                        (candidate.experienceMonth ? candidate.experienceMonth + ' months' : '') :
                        '--'
                    }}
                  </td>
                  <td style="width: 13%">
                    <div
                      *ngIf="candidate.skills?.primarySkills?.length && candidate.skills.primarySkills.length > 0; else noSkills">
                      {{ getSkills(candidate.skills.primarySkills)?.skillsString ?? '--' }}
                      <span
                        *ngIf="(getSkills(candidate.skills.primarySkills)?.pendingSkillsCount ?? 0) > 0"
                        matTooltip="{{ (getSkills(candidate.skills.primarySkills)?.pendingSkills ?? []).join(', ') }}"
                        matTooltipPosition="before"
                        matTooltipClass="custom-tooltip"
                        class="badge badge-pill badge-light">
                          <i class="fas fa-plus fa-xs"></i>
                        <span class="pt-1" style="font-size: 11px">
                          {{ getSkills(candidate.skills.primarySkills)?.pendingSkillsCount ?? 0 }}
                        </span>
                      </span>
                    </div>
                    <ng-template #noSkills>--</ng-template>
                  </td>

                  <td style="width: 10%">
                    <span matTooltip="{{ formatLocationData(candidate.jobType) }}" matTooltipPosition="before"
                          matTooltipClass="custom-tooltip">
                      {{ candidate?.jobType ? candidate?.jobType : '--' }}
                    </span>
                  </td>
                  <td style="width: 13%">
                    {{ candidate?.noticePeriod }}
                  </td>
                  <td style="width: 10%">
                    <label class="switch" style="color: #14804A; font-weight: 500;background-color: #E1FCEF">
                      {{ candidate?.status ?? '--' }}
                    </label>
                  </td>
                  <td style="width: 13%">
                    <div class="d-flex flex-column">
                      <span *ngIf="candidate?.updatedDate" style="font-size: 13px;">
                        Updated On {{ candidate?.updatedDate | date: 'dd MMM y hh:mm a' }}
                      </span>
                      <span *ngIf="candidate?.appliedDate" style="font-size: 13px;">
                        Created On {{ candidate?.appliedDate | date: 'dd MMM y hh:mm a' }}
                      </span>
                    </div>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
          <div *ngIf="!showLoader && candidates.length == 0" class="d-flex justify-content-center mt-8">
            <div>
              <img src="../../../../../assets/images.png" alt=""><br>
              <h4> No Result Found</h4>
            </div>
          </div>
          <mat-paginator [length]="totalEmployee"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[10,12,25,50,100]"
                         [hidden]="totalEmployee == 0"
                         aria-label="Select page"
                         (page)="onPageChange($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Web Customer">
      <div class="col-md-12 mt-4">
        <div class="content-wrapper-main-inner-content">
          <div class="row mb-2">
            <div class="col-md-4">
              <div id="data-table_filter2" class="d-flex justify-content-end dataTables_filter">
                <label class="w-100 search-input">
                  <input [(ngModel)]="customerSearch" (ngModelChange)="updateCustomerSearch()" class="form-control"
                         placeholder="Search Customer" type="search" aria-controls="data-table">
                  <button class="search-button"
                          (click)="this.getAllWebCustomers(this.currentPage,this.pageSize, [],this.search)"
                          type='button'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                        fill="#808080"/>
                    </svg>
                  </button>
                </label>
              </div>
            </div>

            <div class="col-md-8 entry-label d-flex justify-content-end align-items-center">
              <div class="ms-3 p-0">
                <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
                  <i class="fas fa-redo me-2"></i>
                  Reset Filters
                </button>
              </div>
            </div>

          </div>
          <div>
            <table class="table table-striped ">
              <thead class="tableview theme-bg-color">
              <tr>
                <th> Name</th>
                <th>Company Name</th>
                <th>Work Email</th>
                <th>Phone</th>
                <th>Status</th>
                <th>Location</th>
                <th>Date</th>
              </tr>
              </thead>
              <tbody>
              <tr search-filter [searchFields]="searchFieldNameForCustomer" [filters]="customerFilters"
                  (filtersChanged)="onFiltersChangedCustomer($event)" (resetFilters)="clearFilter()"></tr>

              <ng-container *ngFor="let customer of customers">
                <tr>
                  <td style="width: 13%" class="theme-text-color fw-bold cursor-p"
                      [routerLink]="['/dashboard/web_data/customer',customer.id]">
                    {{ customer?.firstName }} {{ customer?.middleName ? customer?.middleName : '' }} {{ customer?.lastName ? customer?.lastName : '' }}
                  </td>
                  <td style="width: 13%">
                    {{ customer?.company ? customer?.company : '--' }}
                  </td>
                  <td style="width: 13%">
                    {{ customer?.email ? customer?.email : '--' }}
                  </td>
                  <td style="width: 13%">
                    {{ customer?.mobile ? customer?.mobile : '--' }}
                  </td>
                  <td style="width: 10%">
                    <label class="switch" style="color: #14804A; font-weight: 500;background-color: #E1FCEF">
                      {{ customer?.verificationStatus ?? '--' }}
                    </label>
                  </td>
                  <td style="width: 10%">
                      {{customer?.addressInformation?.city?.name ?? ''}}, {{customer?.addressInformation?.state?.name ?? ''}},
                    {{customer.addressInformation?.country?.name ?? ''}}
                  </td>
                  <td style="width: 13%">
                    <div class="d-flex flex-column">
                      <span *ngIf="customer?.updatedOn" style="font-size: 13px;">
                        Updated On {{ customer?.updatedOn | date: 'dd MMM y hh:mm a' }}
                      </span>
                      <span *ngIf="customer.createdOn" style="font-size: 13px;">
                        Created On {{ customer.createdOn | date: 'dd MMM y hh:mm a' }}
                      </span>
                    </div>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
          <div *ngIf="!showLoader && customers.length == 0" class="d-flex justify-content-center mt-8">
            <div>
              <img src="../../../../../assets/images.png" alt=""><br>
              <h4> No Result Found</h4>
            </div>
          </div>
          <mat-paginator [length]="totalCustomer"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[10,12,25,50,100]"
                         [hidden]="totalCustomer == 0"
                         aria-label="Select page"
                         (page)="onCustomerPageChange($event)"
          >
          </mat-paginator>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>
