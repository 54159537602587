import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../services/api.service";
import {Router} from "@angular/router";
import {DataService} from "../../services/data.service";
import {Common} from "../../shared/Common";
import {ThemeLoaderService} from "../../services/theme-loader.service";
import Chart from 'chart.js/auto/auto.mjs';
import {Consultant, FeaturedConsultantPayload, Skill} from "../../shared/interfaces";
import {MatDialog} from "@angular/material/dialog";
import {RegisterNow} from "../register-now/register/register-now";
import {MatPaginator} from "@angular/material/paginator";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Observable} from "rxjs/internal/Observable";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-login-client',
  templateUrl: './login-client.component.html',
  styleUrls: ['./login-client.component.scss'],
})
export class LoginClientComponent implements OnInit {

  loginRadio: FormGroup | any;
  visible1 = true;
  visible2 = false;
  password = '';
  loginform: FormGroup;
  showloader = false;
  errorMessage: string = '';
  barChartData: any[] = [];
  barChartCategory: any[] = [];
  search = '';
  filters: FeaturedConsultantPayload = {
    consultantId: "",
    title: "",
    experienceYears: [],
    skills: [],
    query: "",
  };
  searchFields = ['consultantId', 'title', 'ExperienceYear', 'skills'];
  consultants: Consultant[] = [];
  totalElements: any;
  pageSize = 10;
  currentPage = 0;
  totalPages = 0;
  @ViewChild('loginPasswordInput') loginPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconLoginPasswordInputButton') faIconLoginPasswordInputButton: ElementRef<HTMLElement>;
  consultantId: string = '';
  selectedItemsExperience: { min: number, max: number }[] = [];
  selectedSkill: Skill[] = [];
  @ViewChildren('dropdownExperience, dropdownSkills') dropdowns!: QueryList<ElementRef>;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  skillControl = new FormControl('');
  members: any[] = [];
  message: string | null;
  userId: any;
  role: any;
  firstName: any;
  index: number;
  loading: boolean;
  active: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  experienceOptions: { min: number, max: number, label: string }[] = [
    {min: 0, max: 1, label: '0-1 years'},
    {min: 1, max: 3, label: '1-3 years'},
    {min: 3, max: 5, label: '3-5 years'},
    {min: 5, max: 8, label: '5-8 years'},
    {min: 8, max: 10, label: '8-10 years'},
    {min: 10, max: Infinity, label: '10 years+'}
  ];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  skillName = '';
  filteredSkills: Observable<Skill[]>;
  disabledSkills: Skill[] = [];
  skills: any[] = [];
  skillsRes: Skill[] = [];

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private router: Router,
    private dataService: DataService,
    private themeLoaderService: ThemeLoaderService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.getAllFeaturedConsultants(this.currentPage, this.pageSize, '', '');
    this.getSkillChart();
    this.loginRadio = this.fb.group({
      type: [''],
    });
    localStorage.setItem('loginType', 'Client');
    this.loginRadio.get('type').setValue('Client');
    this.loginform = this.fb.group({
      username: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      password: ['', Validators.required]
    });
    if (localStorage.getItem('access_token') && localStorage.getItem('role') == 'Sales') {
      this.router.navigate(['/client']);
    } else if (localStorage.getItem('access_token') && localStorage.getItem('role') == 'ADMIN') {
      this.router.navigate(['/dashboard']);
    }
    this.loginform.get('username')?.valueChanges.pipe(debounceTime(100), distinctUntilChanged()).subscribe(() => {
      this.handleEmailChange();
    });
    this.loginform.get('password')?.valueChanges.subscribe(() => {
      this.handlepasswordChange();
    });
  }

  login(): void {
    if (this.loginform.invalid) {
      this.showloader = false;
      if (this.loginform.get('password')?.errors) {
        if (this.loginform.get('password')?.errors?.required) {
          this.errorMessage = 'Password cannot be empty.';
        }
      }
      if (this.loginform.get('username')?.errors) {
        if (this.loginform.get('username')?.errors?.required) {
          this.errorMessage = 'Email is required.';
        } else if (this.loginform.get('username')?.errors?.pattern) {
          this.errorMessage = 'Please enter a valid email address.';
        }
      }
      if (this.loginform.get('username')?.errors || this.loginform.get('password')?.errors) {
        return;
      }
    } else {
      this.errorMessage = '';
    }
    this.showloader = true;
    this.errorMessage = '';
    const rootVariable = document.documentElement;
    this.service.customerLogin(this.loginform.value).then(res => {
      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('orgId', res.org_id);
      localStorage.setItem('name', res.name);
      localStorage.setItem('userId', res.user_id);
      localStorage.setItem('role', res.role);
      this.service.getCustomerLoginUser().subscribe({
        next: res => {
          const primaryColor = res?.user?.theme?.colour ?? '#76ba991a';
          const secondaryColor = Common.rgbaToHex(Common.hexToRGBA(primaryColor, 0.1));
          const ColorObj = {primaryColor, secondaryColor};
          localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
          rootVariable.style.setProperty('--primaryColor', primaryColor);
          rootVariable.style.setProperty('--secondaryColor', secondaryColor);
          this.themeLoaderService.setTheme(primaryColor, secondaryColor);
        },
        error: err => {
          console.error('Error fetching user theme:', err);
        },
        complete: () => {
          this.dataService.isLoading.next(false);
        }
      });
      this.showloader = false;
      this.router.navigate(['/client']);
    })
      .catch(e => {
        this.showloader = false;
        if (e.error?.status === 400) {
          this.errorMessage = e.error.message || 'Login failed';
        } else {
          this.errorMessage = e.error?.message || 'An error occurred';
        }
      });
  }

  togglePassword(): void {
    if (this.loginPasswordInput.nativeElement.type == 'text') {
      this.loginPasswordInput.nativeElement.type = 'password';
      this.faIconLoginPasswordInputButton.nativeElement.className = 'fas fa-eye';
    } else {
      this.faIconLoginPasswordInputButton.nativeElement.className = 'fas fa-eye-slash';
      this.loginPasswordInput.nativeElement.type = 'text';
    }
  }

  getForgotPassword(): string {
    if (this.loginform.get('username')?.value) {
      return '/forget-password/' + this.loginform.get('username')?.value;
    } else {
      return '/forget-password/NA';
    }
  }

  getSkillChart() {
    this.service.skillsCount().subscribe(
      (res) => {
        const response = res;
        this.barChartCategory = response.map((data: any) => data.title).slice(0, 15);
        this.barChartData = response.map((data: any) => data.percentage).slice(0, 15);
        this.createBarChart();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  createBarChart(): void {
    // @ts-ignore
    new Chart(document.getElementById("bar-chart"), {
      type: 'bar',
      data: {
        labels: this.barChartCategory,
        datasets: [{
          label: 'Consultants Percentage',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          data: this.barChartData,
        }],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            formatter: () => {
              return ``;
            }
          },
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            stacked: true,
          },
          y: {
            stacked: true,
            grid: {
              display: false
            },
            ticks: {
              callback: function (value: number) {
                return value + '%';
              },
            },
            title: {
              display: true,
              text: "CONSULTANT'S   SKILL  WISE  DISTRIBUTION",
              align: 'left',
              position: 'top',
              rotation: -90,
              padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 20,
              }
            }
          }
        }
      }
    });
  }

  getAllFeaturedConsultants(pageNumber: number, size: number, sort: string, query: string,
                            experience?: { min: number; max: number; }[], skills?: number[],
                            title?: string, consultantId?: string): void {
    this.service.getAllFeaturedConsultants(pageNumber, size, sort, query, experience, skills,
      title, consultantId).subscribe({
      next: res => {
        this.consultants = res.content;
        this.pageSize = res.page.size;
        this.totalElements = res.page.totalElements;
        this.totalPages = res.page.totalPages;
      },
      error: err => {
        console.log('error', err);
      },
      complete: () => {
      }
    });
  }

  openRegisterNow(): void {
    const dialogConfig = {
      width: '60%',
      height: 'auto',
    };

    const consultEditDialogRef = this.dialog.open(RegisterNow, dialogConfig);
    consultEditDialogRef.afterClosed().subscribe(() => {
    })
  }

  openLinkFaceBook() {
    window.open('https://www.facebook.com/linkageit', '_blank');
  }

  openLinkInstagram() {
    window.open('https://www.instagram.com/linkageitofficial/', '_blank');
  }

  openLinkLinkdIN() {
    window.open('https://www.linkedin.com/company/linkageit', '_blank');
  }

  handleEmailChange(): void {
    const usernameControl = this.loginform.get('username');
    if (usernameControl?.touched || usernameControl?.dirty) {
      if (usernameControl?.invalid) {
        this.showloader = false;
        if (usernameControl.errors) {
          if (usernameControl.errors.required) {
            this.errorMessage = 'Email is required.';
          } else if (usernameControl.errors.pattern) {
            this.errorMessage = 'Please enter a valid email address.';
          }
        }
      } else {
        this.errorMessage = '';
      }
    }
  }

  handlepasswordChange(): void {
    const passwordControl = this.loginform.get('password');
    if (passwordControl?.touched || passwordControl?.dirty) {
      if (passwordControl?.invalid) {
        this.showloader = false;
        if (passwordControl.errors) {
          if (passwordControl.errors.required) {
            this.errorMessage = 'Password cannot be empty.';
          }
        }
      } else {
        this.errorMessage = '';
      }
    }
  }
}
