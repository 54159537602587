import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompletePartnerComponent } from './content/admin/create-partner/complete-partner/complete-partner.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from "./services/auth.guard";
import {LoginClientComponent} from "./login/login-client/login-client.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {ClientGuard} from "./services/client.guard";
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'admin', redirectTo: 'admin/login', pathMatch: 'full' },
  { path: 'admin/login', component: LoginComponent },
  { path: 'login', component: LoginClientComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'profile-complete/:id', component: CompletePartnerComponent },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
  },
  {
    path: 'client',
    canActivate: [ClientGuard],
    loadChildren: () => import('./client/client-interface-routing.module').then(m => m.ClientInterfaceRoutingModule),
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
