import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {CustomerContact, Members} from "../../../shared/interfaces";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-reassign-customer',
  templateUrl: './reassign-customer.component.html',
  styleUrls: ['./reassign-customer.component.css']
})
export class ReassignCustomerComponent implements OnInit {
  @ViewChild('SPOCAutoCompleter', {read: MatAutocompleteTrigger})
  autoCompleteTrigger: MatAutocompleteTrigger;
  customerContactForm: FormGroup;
  customerControl = new FormControl('');
  customerOptions: any[] = [];
  filteredCustomer!: Observable<any[]>;
  selectedCustomers: any[] = [];
  disabledSkills: any[] = [];
  customers: any[] = [];
  selectedSpcId: string;
  newlyCreatedCustomerId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any,
    private dialogRef: MatDialogRef<ReassignCustomerComponent>,
    private service: ApiService,
    private toast: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.customerContactForm = this.fb.group({
      existingSpoc: [{value: this.Data?.data?.spoc?.firstName + ' ' + this.Data?.data?.spoc?.lastName, disabled: true}],
      existingCustomer: [{value: this.Data?.data?.customer?.companyName, disabled: true}],
      customerId: ["", Validators.required],
    });
    this.getCustomers();
  }

  getCustomers() {
    this.service.getActiveCustomerClients().subscribe((res: any) => {
      this.customerOptions = res.filter((customer: any) => customer?.active && customer?.companyName);
      this.filteredCustomer = this.customerControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterCustomers(value))
      );
    });
  }

  private _filterCustomers(value: any): any[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value?.companyName?.toLowerCase() || '';
    return this.customerOptions.filter(option =>
      option.companyName.toLowerCase().includes(filterValue)
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.openPanel();
    }
  }

  addCustomer() {
    const payload = {
      companyName: this.customerControl.value
    };
    this.service.createCustomer(payload).subscribe((res) => {
      this.newlyCreatedCustomerId = res.id;
      this.toast.success('Customer Created Successfully', 'Success');
      this.customerOptions.push(res);
      this.customerControl.setValue(res.companyName);
      this.filteredCustomer = this.customerControl.valueChanges.pipe(
        startWith(res.companyName),
        map(value => this._filterCustomers(value))
      );
    }, error => {
      this.toast.error('Failed to create customer', error?.error?.message);
    });
  }

  selectedCustomer(event: any) {
    const customer = event?.option?.value;
    if (customer) {
      this.selectedCustomers.push(customer);
      this.customerControl.setValue(customer.companyName);
      this.newlyCreatedCustomerId = null
    }
  }

  assignCustomer() {
    const customerId = this.newlyCreatedCustomerId ?? this.selectedCustomers.map(c => c.id)[0];
    const payload = {
      customerId: Number(customerId),
    };
    this.service.reAssignCustomerContact(this.Data?.data?.id, payload).subscribe((res: any) => {
      this.toast.success('Customer assigned successfully!', 'Success');
      this.closeDialog();
    }, error => {
      this.toast.error(error.error?.detail, 'Error');
    });
  }
}
