<div *ngIf="role !='TA' && openDialogType!='READONLY' " class="vendor_wrapper">
  <h2 class="mb-4 font-weight-bold">Add Contacts</h2>
  <form [formGroup]="contactForm" ngNativeValidate (submit)="addContact()">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" formControlName="name" #inputContactName>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red mb-3">
          <mat-label>Designation</mat-label>
          <input #autoCompleteInput2 [formControl]="desControl" [(ngModel)]="desTypeName"
                 [matAutocomplete]="auto2"
                 type="text" matInput placeholder="e.g. CEO, HR, CTO"
                 aria-describedby="title" (change)="errorMessage = null">
          <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayDocFn">
            <mat-option *ngFor=" let des of filteredDesignation| async"
                        (onSelectionChange)="chooseDesType($event)" [value]="des">
              {{ des.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="(filteredDesignation|async)?.length == 0" class="dropdown-skills position-relative">
          <ul>
            <li class="li_disabled">
              No match Found
            </li>
            <hr class="m-0 p-0">
            <li class="font-weight-bold">
              <div class="d-flex justify-content-between align-items-center"
                   (click)="addDesType($event)">
                <span>Add this Designation type:</span>
                <button class="p-0 bg-transparent border-0 fs-20"> +</button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Email id</mat-label>
          <input matInput placeholder="Email" formControlName="email" type="email">
          <div *ngIf="field == 'ContactName'" class="alert ml-5">
            <div>
              {{ this.errorMessage }}
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red mb-3">
          <mat-label>Mobile number</mat-label>
          <input matInput placeholder="Mobile" minlength="10" maxlength="10" formControlName="phone" type="text"
                 (input)="onKeyPress($event)">
        </mat-form-field>
        <div *ngIf="displayError" class="text-danger">
          {{ this.errorMessage }}
        </div>
      </div>

      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>LinkedIn URL</mat-label>
          <input matInput placeholder="LinkedIn URL" formControlName="linkedIn" type="text">
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex justify-content-end align-items-baseline mt-4">
      <div class="save-skill">
        <button (click)="addContact()" *ngIf="!showloader" class="btn btn-save mx-3 theme-bg-color"
                type="submit">Save details
        </button>
        <div *ngIf="showloader" disabled>
          <button class="btn btn-save theme-btn-color" disabled>
            <div class="spinner-border spinner-border-sm me-2" role="status">
              <span class="sr-only"></span>
            </div>
            Save Details
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="vendorContacts.length == 0 && openDialogType == 'READONLY'"
     class="empty_table_wrapper theme-border-color mt-5">
  <div *ngIf="!showloader">
    <span>Currently no active contacts available</span>
  </div>
</div>
<div *ngIf="vendorContacts.length > 0" class="document-wrapper mb-5 ">
  <div class="mx-height-scroll mt-4">
    <form [formGroup]="contactForm">
      <table class="table table-striped">
        <thead class="tableview theme-bg-color">
        <tr>
          <th>Name</th>
          <th>Designation</th>
          <th>Email id</th>
          <th>Mobile number</th>
          <th>LinkedIn URL</th>
          <th *ngIf="role!='TA' && role !=='MANAGER' && openDialogType!='READONLY'">Actions</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let contact of vendorContacts; index as i;">
          <tr>
            <td style="margin-right: 45px;text-transform:capitalize;font-size: 14px">
              {{ contact.name }}
            </td>
            <td style="margin-right: 45px;font-size: 14px">
              {{ contact.designation }}
            </td>
            <td style="margin-right: 45px;font-size: 14px">
              {{ contact.email }}
            </td>
            <td style="margin-right: 45px;font-size: 14px">
              {{ contact.phone }}
            </td>
            <td style="margin-right: 45px;font-size: 14px">
              <span (click)="goToLink(contact.linkedIn)"><a style="color: blue; font-style: italic; text-decoration: underline;cursor: pointer" >{{contact.linkedIn}}</a></span>
<!--              <a> {{ contact.linkedIn }}</a>-->
            </td>
            <td *ngIf="role!='TA' && role!= 'MANAGER' && openDialogType!='READONLY'" class="p-1">
              <a (click)="editContact(contact)" type="button" class="me-3 action_icon">
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                    fill="#545454"/>
                </svg>
              </a>
              <a *ngIf="role!='TA' && role!= 'MANAGER'" (click)="deleteRow(contact)" type="button"
                 class="me-3 action_icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                    fill="#ED0F0F"/>
                </svg>
              </a>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </form>
  </div>
</div>
<mat-paginator [length]="totalVendorContact"
               [pageSize]="pageSize"
               [pageSizeOptions]="[5,10,12,25,50,100]"
               [hidden]="totalVendorContact == 0"
               aria-label="Select page">
</mat-paginator>
<div  *ngIf="vendorContacts.length == 0 && openDialogType !== 'READONLY'" class="d-flex align-items-center justify-content-center fs-6 mt-5 mb-5">
  <span>Currently no active contact available</span>
</div>

