<div class="content-wrapper" *ngIf="currentRoute === 'candidate'">
  <span class="ps-3" style="font-size: 20px; font-weight: 500">Setting</span>
  <form [formGroup]="statusForm" class="ms-3 me-3">
    <div class="d-flex align-items-center justify-content-between">
      <div class="flex-grow-1">
          <span style="font-size: 16px; font-weight: 500">Status</span>
          <mat-form-field appearance="outline" class="w-100 pt-2">
            <mat-label>Candidate Status</mat-label>
            <mat-select formControlName="status">
              <mat-option [value]="'SHORTLISTED'">SHORTLISTED</mat-option>
              <mat-option [value]="'NOT_SHORTLISTED'">NOT SHORTLISTED</mat-option>
            </mat-select>
          </mat-form-field>
      </div>
      <div class="ms-2 d-flex align-items-center justify-content-center pt-3">
        <app-button [width]="'150px'" [height]="'40px'" type="primary" (buttonClick)="onSubmit()"
                    [isLoading]="loading">
          Save
        </app-button>
      </div>
    </div>
  </form>

  <div class="ps-3 pe-3">
    <h6>Upgrade Candidate</h6>
    <button class="btn theme-border-color theme-text-color w-100"
            (click)="upgradeToConsultant()"
            [disabled]="isDisabled"
    >
      Upgrade to Consultant
    </button>
  </div>
</div>

<div class="content-wrapper" *ngIf="currentRoute === 'customer'">
  <span class="ps-3" style="font-size: 20px; font-weight: 500">Settings</span>
  <div class="ps-3 pe-3">
      <div class="mb-2 mt-2">
        <button class="btn theme-border-color theme-text-color w-100"
                (click)="openCustomerContactDialog()">
          <div>Assign Customer</div>
        </button>
      </div>
  </div>
</div>
