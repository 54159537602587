<div class="modal-popUp-content content-wrapper-main-inner-content job_wrapper">
  <div class="member_wrapper theme-bg-color" mat-dialog-title>
    <div class="row mt-30 modal-inner-content">
      <div class="col-md-6 content-header">
        <div>
          <h1 class="page-title text-white">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99992 29.8334C7.26658 29.8334 6.63903 29.5725 6.11725 29.0508C5.59459 28.5281 5.33325 27.9001 5.33325 27.1668V5.83342C5.33325 5.10008 5.59459 4.47208 6.11725 3.94941C6.63903 3.42764 7.26658 3.16675 7.99992 3.16675H18.6666L26.6666 11.1667V16.5001H23.9999V12.5001H17.3332V5.83342H7.99992V27.1668H15.9999V29.8334H7.99992ZM7.99992 27.1668V5.83342V27.1668ZM24.3999 19.8668L25.8332 21.3001L20.6666 26.4334V27.8334H22.0666L27.2332 22.7001L28.6332 24.1001L22.8999 29.8334H18.6666V25.6001L24.3999 19.8668ZM28.6332 24.1001L24.3999 19.8668L26.3332 17.9334C26.5777 17.689 26.8888 17.5668 27.2666 17.5668C27.6444 17.5668 27.9555 17.689 28.1999 17.9334L30.5666 20.3001C30.811 20.5445 30.9332 20.8556 30.9332 21.2334C30.9332 21.6112 30.811 21.9223 30.5666 22.1668L28.6332 24.1001Z"
                fill="white"/>
            </svg>
            {{ type === 'edit' ? 'Edit Job' : 'Create Job' }}
          </h1>
        </div>
      </div>
      <div class="col-md-6 d-flex justify-content-end align-items-center">
        <div type="button" (click)="close()" class="cross_icon themeSecondary-bg-color">
          <a>
            <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
              <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography m-0 p-0">
    <div class="d-flex justify-content-center">
      <div class="create-vendor col-md-10 mt-5 mb-5">
        <form [formGroup]="jobForm" class="member-form" ngNativeValidate>
          <div class="form-row mb-4 create_header" style="justify-content: center; display: flex; ">
            <label
              style="font-weight: bolder;font-size: 30px;">{{ type === 'edit' ? 'Edit Job' : 'Create Job' }} </label>
            <div *ngIf="this.type=='edit'" class="col-md-12 d-flex justify-content-end align-items-baseline mx-0"
                 id='search-box2'>
              <mat-slide-toggle
                color="primary"
                [checked]="jobStatus"
                (change)="toggleProfileJobStatus($event)">
                <div class="ActiveButton">Active</div>
              </mat-slide-toggle>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Client Name</mat-label>
                <input #clientNameAutoCompleter
                       type="text"
                       placeholder="Pick one"
                       aria-label="Number"
                       matInput
                       [formControl]="clientControl"
                       [matAutocomplete]="auto1" required>
                <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" [displayWith]="displayDocFn"
                                  (optionSelected)="getAllClientContacts($event,'ADD')">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{ option.customerId + ' | ' + option.companyName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="form-group col-md-6">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Client Contact</mat-label>
                <mat-select [(value)]="selectedCustomerClient" formControlName="customerContact"
                            (selectionChange)="selectContactId($event)" [compareWith]="compareContacts" required>
                  <mat-option *ngFor="let contact of clientContactList" [value]="contact">
                    {{ contact?.firstName ? contact.firstName : '--' }} {{ contact?.lastName ? contact?.lastName : '' }}
                    | {{ contact?.designation }} | {{ contact?.email }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>No. of openings</mat-label>
                <input matInput formControlName="noOfOpenings">
                <mat-error *ngIf="jobForm.get('noOfOpenings')?.hasError('pattern')">
                  No. of opening should be numeric value.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-md-4">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Job Type</mat-label>
                <mat-select formControlName="jobTypes" [required]="true" multiple>
                  <mat-option *ngFor="let jobType of jobTypes" [value]="jobType" class="dropItems"
                              (click)="$event.stopImmediatePropagation()">
                    <a class="dropdown-item">
                      {{ jobType }}
                    </a>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group col-md-4">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Work Location</mat-label>
                <mat-select formControlName="workLocations" [required]="true" multiple>
                  <mat-option *ngFor="let location of workLocations" [value]="location" class="dropItems"
                              (click)="$event.stopImmediatePropagation()">
                    <a class="dropdown-item">
                      {{ location }}
                    </a>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-8">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Job Title</mat-label>
                <input matInput placeholder="Enter Job Title" formControlName="title" required>
              </mat-form-field>
            </div>
            <div class="form-group col-md-2">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Min. Experience(Years)</mat-label>
                <mat-select formControlName="minExperience" required>
                  <mat-option *ngFor="let vendor of minimumExperience" [value]="vendor" class="dropItems"
                              (click)="$event.stopImmediatePropagation()">
                    <a class="dropdown-item">
                      {{ vendor }}
                    </a>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group col-md-2">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Max. Experiences(Years)</mat-label>
                <mat-select formControlName="maxExperience" [required]="true">
                  <mat-option *ngFor="let vendor of maximumExperience" [value]="vendor" class="dropItems"
                              (click)="$event.stopImmediatePropagation()">
                    <a class="dropdown-item">
                      {{ vendor }}
                    </a>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <div *ngIf="(filteredSkills|async)?.length !== 0 && !exactMatchFound && skillCtrl.value"
                   class="dropdown-skills position-relative mb-3">
                <ul>
                  <li class="font-weight-bold">
                    <div class="d-flex justify-content-between align-items-center" (click)="addSkillType()">
                      <span>Add this Skill to Skill Types</span>
                      <button class="p-0 bg-transparent border-0 fs-20"> +</button>
                    </div>
                  </li>
                </ul>
              </div>
              <mat-form-field appearance="outline" class="w-100 border-red w-100 skills_drop">
                <mat-label>Skills</mat-label>
                <mat-chip-list #chipList aria-label="skill selection" required>
                  <mat-chip
                    *ngFor="let skill of skills"
                    [disabled]="jobCreatedBy"
                    (removed)="removeSkill(skill)">
                    {{ skill.title }}
                    <button matChipRemove><span>&#x2716;</span></button>
                  </mat-chip>
                  <input #skillAutoCompleter
                         placeholder="Add your skills..."
                         #chipGrid
                         [formControl]="skillCtrl"
                         [matAutocomplete]="auto"
                         [matChipInputFor]="chipList"
                         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                         [(ngModel)]="skillName"
                         [matChipInputAddOnBlur]="addOnBlur"
                         #autoTrigger='matAutocompleteTrigger'
                         (click)="openAutocomplete(autoTrigger)"
                         [disabled]="this.jobCreatedBy"
                         required
                  >

                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkills($event)">
                  <mat-option *ngFor="let skill of filteredSkills | async" [value]="skill"
                              [disabled]="disableSkill(skill)">
                    {{ skill.title }}
                  </mat-option>
                </mat-autocomplete>

              </mat-form-field>

              <div *ngIf="(filteredSkills|async)?.length == 0" class="dropdown-skills position-relative">
                <ul>
                  <li class="li_disabled">
                    No match Found
                  </li>
                  <hr class="m-0 p-0">
                  <li class="font-weight-bold">
                    <div class="d-flex justify-content-between align-items-center" (click)="addSkillType()">
                      <span>Add this Skill to Skill Types</span>
                      <button class="p-0 bg-transparent border-0 fs-20"> +</button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Work Time Zone</mat-label>
                <input #timeZoneAutoCompleter type="text" matInput placeholder="Work Time Zone"
                       [formControl]="workTimeZoneControl" [matAutocomplete]="auto3">
                <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="onTimeZoneSelected($event)">
                  <mat-option *ngFor="let timeZone of filteredTimeZones | async"
                              [value]="( '(GMT ' + timeZone.offset + ')' + ' ' + timeZone.value)"
                              [matTooltip]="timeZone.value" matTooltipClass="custom-tooltip"
                  >
                    (GMT {{ timeZone.offset }})  {{ timeZone.value }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="form-group col-md-6">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-select formControlName="duration" required>
                  <mat-option *ngFor="let duration of durations" [value]="duration" class="dropItems"
                              (click)="$event.stopImmediatePropagation()">
                    <a class="dropdown-item">
                      {{ duration }}
                    </a>
                  </mat-option>
                </mat-select>
                <mat-label>Duration(In Months)</mat-label>
              </mat-form-field>
            </div>
          </div>
          <app-expandable-texteditor
            label="Job Description"
            placeholder="Job Description"
            [formControl]="getFormControl('description')"
          >
          </app-expandable-texteditor>

          <div class="form-row">
            <div class="form-group col-md-12">
              <mat-form-field appearance="outline" class="w-100 border-red">
                <mat-label>Notes</mat-label>
                <textarea matInput placeholder="Enter Job Description" formControlName="notes" rows="3"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="jobError" class="containeroferror">
            <div style="justify-content: center">
              {{ this.jobError }}
            </div>
          </div>

          <div class="d-flex justify-content-center align-items-baseline mt-4">
            <div class="form-group btns save-form">
              <div class="d-flex justify-content-center">
                <button type="button" *ngIf="!showloader" class="action-btn theme-btn-color" (click)="addJob()">Save
                </button>
                <button *ngIf="showloader" class="action-btn theme-btn-color" disabled>
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>
</div>
