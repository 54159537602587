<div class="modal-popUp-content content-wrapper-main-inner-content job_wrapper overflow-hidden">
  <div class="member_wrapper theme-bg-color" mat-dialog-title>
    <div class="row mt-30 modal-inner-content">
      <div class="col-md-4 content-header">
        <div class="d-flex align-items-md-start">
          <h1 class="page-title text-white me-3">
            <svg width="34" height="44" viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.95 0.399902C11.1866 0.399902 9.68642 1.52688 9.13043 3.0999H4.84999C2.61323 3.0999 0.799988 4.91314 0.799988 7.1499V39.5499C0.799988 41.7866 2.61323 43.5999 4.84999 43.5999H29.15C31.3867 43.5999 33.2 41.7866 33.2 39.5499V7.1499C33.2 4.91314 31.3867 3.0999 29.15 3.0999H24.8694C24.3135 1.52688 22.8134 0.399902 21.05 0.399902H12.95ZM11.6 4.4499C11.6 3.70432 12.2044 3.0999 12.95 3.0999H21.05C21.7955 3.0999 22.4 3.70432 22.4 4.4499C22.4 5.19548 21.7955 5.7999 21.05 5.7999H12.95C12.2044 5.7999 11.6 5.19548 11.6 4.4499ZM24.7047 21.6045L15.2546 31.0546C14.7274 31.5817 13.8726 31.5817 13.3454 31.0546L9.2954 27.0046C8.76817 26.4773 8.76817 25.6225 9.2954 25.0952C9.8226 24.5681 10.6774 24.5681 11.2046 25.0952L14.3 28.1907L22.7953 19.6953C23.3226 19.1681 24.1774 19.1681 24.7047 19.6953C25.2317 20.2225 25.2317 21.0773 24.7047 21.6045Z"
                fill="white"/>
            </svg>
          </h1>
          <div class="">
            <h6 class="heading-label-style">Task ID: <span
              class="text-white"> {{ (myTaskData != null) ? myTaskData?.uniqueId : '' }}</span></h6>
            <h6 class="label-style">{{ myTaskData?.startDate | date:'  dd MMM yyyy ,  hh:mm a ' }}</h6>
          </div>
        </div>

      </div>
      <div class="col-md-8 d-flex justify-content-end align-items-center">
        <div class="cross_icon" (click)="close()">
          <a>
            <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2872 1L1 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <path d="M1 1L17.2872 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography m-0">
    <div class="row">
      <mat-tab-group class="remove-border-bottom"
                     mat-align-tabs="center"
                     animationDuration="400ms"
                     #tabGroup>

        <mat-tab label="Task Detail">
          <app-task-detail [data]="data"></app-task-detail>
        </mat-tab>
        <mat-tab label="Client Communication">
          <app-client-communication></app-client-communication>
        </mat-tab>
        <mat-tab label="Internal Communication">
          <app-internal-communication></app-internal-communication>
        </mat-tab>
        <mat-tab label="Settings">
          <app-my-task-settings (hasChangedEvent)="handleHasChangedEvent($event)"></app-my-task-settings>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-dialog-content>
</div>
